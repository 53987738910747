var toggleModal = require('../components/toggleModal');


function checkEmptyProperties(object) {
    for (var key of Object.keys(object)) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            if (object[key] === '' || object[key] === null) {
                return true;
            }
        }
    }
    return false;
}

function searchTrip() {
    $('.search-tickets-btn-home').on('click', function (e) {
        e.preventDefault();
        var origin = $('#origin').val();
        var destination = $('#destination').val();
        var inputDeparture = $('#inputDeparture').val();
        var inputBack = $('#inputBack').val();

        if (origin.length < 1 || destination.length < 1 || inputDeparture.length < 1) {
            if (origin.length < 1) {
                $('.cities__origin').addClass('is-invalid');
            }

            if (destination.length < 1) {
                $('.cities__destination').addClass('is-invalid');
            }

            if (inputDeparture.length < 1) {
                $('.dates__departure').addClass('is-invalid');
            }
            return;
        }

        // evento reservamos
        var inputDepartureEl = document.querySelector('#inputDeparture');
        let dateInfo = window.transformDateAndCalculateDays(inputDepartureEl.value)
        const originCity = $('.cities__input.cities__origin').val();
        const destinationCity = $('.cities__input.cities__destination').val();

        let dataSearch = {
            Departure: dateInfo.formattedNewDate,
            "Departure Delta": dateInfo.daysRemaining,
            Destination: destinationCity,
            Origin:originCity,
            "Origin Terminal": originCity,
            Passengers: 1,
            Route: `${originCity} - ${destinationCity}`,
            "Destination Terminal": destinationCity,
        };

        let isStudent = $('#isStudent').prop('checked');

        if (isStudent) {
            dataSearch['Categories'] = ["Estudante"]
        }

        $(window).trigger('reservamos:search', dataSearch);
        $('body').trigger('amplitude:searchEvent');

        var searchData = {
            origin: $('#origin').val(),
            destination: $('#destination').val(),
            isStudent: $('#isStudent').is(':checked'),
            isPCD: $('#isPCD').is(':checked'),
            showEmpty: true,
            step: 1,
            date: $('#inputDeparture').val()
        };

        if (inputBack) searchData.returnDate = inputBack;

        const queryParams = '?' + new URLSearchParams(searchData).toString() + location.search.replace('?', '&');
        const urlStorefront = $('.link-mob').val();
        const url = urlStorefront + '/busca' + queryParams + '&fixUrl=true';
        window.location.href = url
    });

    $('.search-tickets-btn').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var form = $('#ticketFormSuburbano');
        var url = form.attr('action');
        var dataForm = {
            origin: $('#origin').val(),
            destination: $('#destination').val(),
            isStudent: $('#isStudent').is(':checked'),
            isPCD: $('#isPCD').is(':checked'),
            showEmpty: true,
            step: 1,
            date: $('#inputDeparture').val()
        };

        if (checkEmptyProperties(dataForm)) {
            toggleModal.showError({ title: '', message: 'Não foi possível realizar a busca, verifique as informações digitadas.' });
            return;
        }

        let queryParams = Object.keys(dataForm).map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(dataForm[key]);
        }).join('&');
        $('body').trigger('busLoading:init');

        queryParams = '?' + queryParams;
        $.ajax({
            url: url + queryParams + '&fixUrl=true',
            method: 'get',
            data: dataForm,
            success: function (response) {
                if (response.error) {
                    toggleModal.showError({ title: '', message: response.errorMessage });
                } else {
                    $('.search-result').html(response);
                    $('.search-result').removeClass('d-none');
                    $('html, body').animate({
                        scrollTop: $('.search-result').offset().top - 110
                    }, 1000);
                }
            },
            error: function (error) {
                console.error(error);
                if (error.error) {
                    toggleModal.showError({ title: '', message: error.errorMessage });
                    return;
                }
            },
            complete: function () {
                $('body').trigger('busLoading:stop');
            }
        });
    });

    $('.cities__origin #origin').on('change input', function () {
        $('.cities__origin').removeClass('is-invalid');
    });
    $('.cities__destination #destination').on('change input', function () {
        $('.cities__destination').removeClass('is-invalid');
    });

    $('.dates__departure').on('change input', function () {
        $('.dates__departure #inputDeparture').removeClass('is-invalid');
    });
}

module.exports = {
    init: function () {
        searchTrip();

        setTimeout(() => {
            var bgBannerDesktop = $('.hero_bg').data('bg-desktop') || 'none';
            var bgBannerMobile = $('.hero_bg').data('bg-mobile') || 'none';
            $(':root').css({ '--bgBannerDesktopLP': 'url(' + bgBannerDesktop + ')', '--bgBannerMobileLP': 'url(' + bgBannerMobile + ')' });

        }, 500);


    }


};
