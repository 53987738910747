function convertBase64(file) {
    return new Promise(function (resolve, reject) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = function () {
            resolve(fileReader.result);
        };
        fileReader.onerror = function (error) {
            reject(error);
        };
    });
}


module.exports = {
    inputFileName: function () {
        $('#curriculo').on('change', function () {
            var fileName = $(this)[0].files[0].name;
            $(this).closest('.custom-file').find('.custom-file-label').html(fileName);
        });
        $('#file').on('change', function () {
            var fileName = $(this)[0].files[0].name;
            $(this).closest('.custom-file').find('.custom-file-label').html(fileName);
        });
    },
    sendCv: function () {
        $(".btn-send-cv").on('click', function (event) {
            event.preventDefault();
            $("form[name='form-cv']").unbind('submit');
            var form = $("form[name='form-cv']");
            $('.error-message').hide().text('');
            form.spinner().start();

            const name = $('#inputName').val() + ' ' + $('#inputLastName').val();
            const email = $('#inputEmail').val();
            const phone = $('#inputTel').val();
            const address = $('#inputAddress').val() + ' - ' + $('#inputBairo').val() + ' - ' + $('#inputlastCity').val();
            const position = $('#inputVagas').val();
            const filial = $('#inputFiliais').val();
            const curriculum = $('#curriculo')[0].files[0];

            convertBase64(curriculum)
                .then(function (curriculumBase64) {
                    return new Promise(function (resolve, reject) {
                        const formData = new FormData();
                        formData.append('name', name);
                        formData.append('email', email);
                        formData.append('phone', phone);
                        formData.append('curriculum', curriculumBase64);
                        formData.append('fileName', curriculum.name);
                        formData.append('address', address);
                        formData.append('position', position);
                        formData.append('filial', filial);

                        const url = form.attr('action');

                        $.ajax({
                            url: url,
                            type: 'POST',
                            data: formData,
                            processData: false,
                            contentType: false,
                            success: function (response) {
                                resolve(response);
                            },
                            error: function (error) {
                                reject(error);
                            }
                        });
                    });
                })
                .then(function (response) {
                    form.spinner().stop();

                    if (response.success) {
                        form.hide();
                        $('.success-message').show();
                    } else {
                        $('.error-message').text(response.error || 'Ocorreu um erro ao enviar o currículo.').show();
                    }
                })
                .catch(function (error) {
                    form.spinner().stop();
                    $('.error-message').text(error.message || 'Ocorreu um erro ao enviar o currículo.').show();
                    console.error(error);
                });
        });
    },
    sendLGPD: function () {
        $("form[name='form-lgpd']").submit(function (event) {
            $(this).unbind('submit');
            event.preventDefault();
            var form = $(this);
            form.spinner().start();

            const name = $('#inputName').val();
            const email = $('#inputEmail').val();
            const phone = $('#inputTel').val();
            const motivo = $('#inputMotivos').val();
            const mensagem = $('#mensagem').val();
            const curriculum = $('#file')[0].files[0];

            convertBase64(curriculum)
                .then(function (curriculumBase64) {
                    const formData = new FormData();
                    formData.append('name', name);
                    formData.append('email', email);
                    formData.append('phone', phone);
                    formData.append('mensagem', mensagem);
                    formData.append('motivo', motivo);
                    formData.append('file', curriculumBase64);
                    formData.append('fileName', curriculum.name);

                    const url = form.attr('action');

                    return new Promise(function (resolve, reject) {
                        $.ajax({
                            url: url,
                            type: 'POST',
                            data: formData,
                            processData: false,
                            contentType: false,
                            success: function (response) {
                                resolve(response);
                            },
                            error: function (error) {
                                reject(error);
                            }
                        });
                    });
                })
                .then(function (response) {
                    form.spinner().stop();
                    form.hide();
                    $('.success-message').show();
                    console.warn(response);
                })
                .catch(function (error) {
                    form.spinner().stop();
                    form.hide();
                    $('.error-message').show();
                    console.error(error);
                });
        });
    }
};
